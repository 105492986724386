/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {`
          {
            "@context": "http://www.schema.org",
            "@type": "ProfessionalService",
            "name": "Fully Activated Osteopathy",
            "url": "https://fullyactivated.com.au/",
            "sameAs": [
              "https://www.facebook.com/fullyactivatedosteopathy/",
              "https://www.instagram.com/fullyactivatedosteopathy/"
            ],
            "logo": "https://fullyactivated.com.au/assets/images/logo.jpg",
            "image": "https://fullyactivated.com.au/assets/images/iStock-613759142.jpg",
            "description": "Fully Activated is an Osteopathic clinic located in Hoppers Crossing in the heart of Melbourne's West. We treat a variety of conditions including low back pain, neck pain, headaches, migraines, shoulder pain and knee pain",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": " 32 Moffat Crescent",
              "addressLocality": "Hoppers Crossing",
              "addressRegion": "Victoria",
              "postalCode": "3029",
              "addressCountry": "Australia"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "-37.877880",
              "longitude": "144.697041"
            },
            "hasMap": "https://maps.app.goo.gl/Wa8iaZUK7u3fRs3e7",
            "openingHours": "Mo 08:00-19:00 Tu 08:00-19:00 We 08:00-19:00 Th 08:00-19:00 Fr 08:00-16:00 Sa 08:00-13:00",
            "telephone": "+61413 078 633"
          }
        `}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
